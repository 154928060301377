"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.indicatorCell = exports.indicatorContainer = exports.chevronIcon = exports.control = exports.image = exports.imagesContainer = exports.root = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _media = require("../../constants/media");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var root = function root(contentWidth) {
  return {
    width: "".concat(contentWidth, "%"),
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  };
};

exports.root = root;

var imagesContainer = function imagesContainer(amount, imageIndex) {
  return {
    display: 'flex',
    width: "".concat(amount * 100, "%"),
    transition: 'all 0.35s ease-in',
    transform: "translateX(-".concat(100 / amount * imageIndex, "%)")
  };
};

exports.imagesContainer = imagesContainer;

var image = function image(index, activeImageIndex) {
  return {
    flexGrow: 1,
    width: index === activeImageIndex ? 'calc(100% + 1px)' : '100%'
  };
};

exports.image = image;

var control = function control(position) {
  var defaultStyle = (0, _defineProperty2["default"])({
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    width: '15%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    color: '#ffffff',
    opacity: 0.5,
    cursor: 'pointer',
    ':hover': {
      opacity: 0.9
    }
  }, _media.PHONE_MEDIA, {
    display: 'none'
  });

  switch (position) {
    case 'right':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        right: '0px'
      });

    default:
      return _objectSpread({}, defaultStyle);
  }
};

exports.control = control;
var chevronIcon = {
  filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, .6))'
};
exports.chevronIcon = chevronIcon;
var indicatorContainer = {
  position: 'absolute',
  bottom: '10px',
  left: '20%',
  width: '60%',
  textAlign: 'center'
};
exports.indicatorContainer = indicatorContainer;

var indicatorCell = function indicatorCell(isCurrent) {
  return (0, _defineProperty2["default"])({
    display: 'inline-block',
    width: '12px',
    height: '12px',
    margin: '5px 7px',
    cursor: 'pointer',
    backgroundColor: '#666',
    borderRadius: '50%',
    opacity: isCurrent ? 1 : 0.5,
    ':hover': {
      opacity: 1
    }
  }, _media.PHONE_MEDIA, {
    width: '8px',
    height: '8px',
    margin: '2px 5px'
  });
};

exports.indicatorCell = indicatorCell;